import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../config/environments/environment';

export * from './ui.module';

// converter
export * from './app/components/converter/field-converter.service';
export * from './app/components/converter/date-converter.service';
export * from './app/components/converter/kolibri-entity-converter.service';

// data components
export * from './app/components/data/autocomplete/autocomplete-input/autocomplete-input.component';
export * from './app/components/data/autocomplete/choice-autocomplete-input/choice-autocomplete-input.component';
export * from './app/components/data/autocomplete/entity-autocomplete-input/entity-autocomplete-input.component';
export * from './app/components/data/autocomplete/custom-list-autocomplete-input/custom-list-autocomplete-input.component';
export * from './app/components/data/autocomplete/field-autocomplete-input/field-autocomplete-input.component';
export * from './app/components/data/calendar/calendar.component';
export * from './app/components/data/input-wrapper/input-wrapper.component';
export * from './app/components/data/input-text/input-text.component';
export * from './app/components/data/large-textfield/large-textfield.component';
export * from './app/components/data/password-input/password-input.component';
export * from './app/components/data/query-builder/query-builder-group/query-builder-group.component';
export * from './app/components/data/query-builder/query-builder-rule/query-builder-rule.component';
export * from './app/components/data/query-builder/query-builder/query-builder.component';
export * from './app/components/data/search-input/search-input.component';
export * from './app/components/structure/datatable/column-picker/column-picker.component';
export * from './app/components/data/custom-input';

// entities
export * from './app/entities/button-entities';
export * from './app/entities/command-select-item';
export * from './app/entities/kanban-item';
export * from './app/entities/date-range-preset';
export * from './app/entities/multi-level-entry';
export * from './app/entities/query-operator';
export * from './app/entities/datatable-action';
export * from './app/entities/layout-view-mode';

// dialog components
export * from './app/components/dialog/confirm-dialog/confirm-dialog.component';
export * from './app/components/dialog/dialog/dialog.component';
export * from './app/components/dialog/wizard-page/wizard-page.component';
export * from './app/components/dialog/wizard/wizard.component';

// structural components
export * from './app/components/structure/datatable/datatable/datatable.component';
export * from './app/components/structure/toast/toast.component';

// services
export * from './app/service/one-confirm.service';
export * from './app/service/one-dialog.service';
export * from './app/service/side-bar.service';
export * from './app/service/structure.service';
export * from './app/service/query-builder.service';
export * from './app/service/multi-tab-synchronizer.service';
export * from './app/service/dynamic-content.service';
export * from './app/service/redirector.service';
export * from './app/service/layout-condition.service';
export * from './app/service/guidedTour.service';
// util
export * from './app/util/list-component';
export * from './app/util/ui-util';
export * from './app/util/form-component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, `${environment.serverAdd}/translations/${(window as any).modelHash}/`);
}
