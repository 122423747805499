import {
  AbstractActivityLogService,
  AbstractDocumentService,
  AbstractKolibriFileService,
  AbstractModelDeploymentService,
  AbstractModelService,
  AbstractSettingsService,
  MomentUtil,
  Semantic,
  Utility
} from '..';
import {AbstractCriteriaFactory} from '../criteria/abstract-criteria-factory';
import {AbstractKolibriHttpClient} from './abstract-kolibri-http-client';
import {AbstractKolibriMessage} from './abstract-kolibri-message';
import {AbstractKolibriObservableService} from './abstract-kolibri-observable.service';
import {AbstractKolibriRecordFactory} from './abstract-kolibri-record-factory';
import {AbstractKolibriSecurityService} from './abstract-kolibri-security.service';
import {AbstractKolibriTemplate} from './abstract-kolibri-template';
import {AbstractKolibriTranslate} from './abstract-kolibri-translate';

export abstract class AbstractKolibriScriptLibrary {
  public util: typeof Utility = Utility;
  public momentUtil: typeof MomentUtil = MomentUtil;
  public semantic: typeof Semantic = Semantic;

  protected constructor(public record: AbstractKolibriRecordFactory, public criteria: AbstractCriteriaFactory,
                        public http: AbstractKolibriHttpClient, public message: AbstractKolibriMessage,
                        public model: AbstractModelService, public observable: AbstractKolibriObservableService,
                        public translate: AbstractKolibriTranslate, public template: AbstractKolibriTemplate,
                        public activity: AbstractActivityLogService, public deployment: AbstractModelDeploymentService,
                        public documents: AbstractDocumentService, public file: AbstractKolibriFileService,
                        public security: AbstractKolibriSecurityService, public settings: AbstractSettingsService) {
  }
}
